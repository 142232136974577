import { IconTools } from '@veneer/core';
import tokens from '@veneer/tokens';
import { MainTheme } from 'src/styles/styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  gap: ${tokens.layout.size5};
  padding: ${tokens.layout.size5} 40px;
  max-width: 1140px;
  margin: 0 auto;

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
    padding: ${tokens.layout.size5} ${tokens.layout.size8};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
    padding: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
    padding: ${tokens.layout.size4};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  & > div {
    width: 100%;
    max-width: 1140px;
  }

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    gap: ${tokens.layout.size4};
  }

  @media (${MainTheme.mediaQueries.small}) {
    gap: ${tokens.layout.size5};
  }

  @media (${MainTheme.mediaQueries.medium}) {
    gap: ${tokens.layout.size8};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const StyledIconTools = styled(IconTools)`
  && {
    color: ${tokens.color.gray6};
    height: ${tokens.layout.size8};
    width: ${tokens.layout.size8};
  }
`;
