import {
  ACTIONS,
  eventDetailVersion,
  activity,
  AnalyticsEventPropTypes
} from '@hpx-core-experiences/react-my-account-commons/dist';
import { ScreenName, ScreenPath } from 'src/utils/constants';

export const OrdersScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath: ScreenPath,
  screenName: ScreenName,
  version: eventDetailVersion
};

export const OrdersError = (errorInfo: string): AnalyticsEventPropTypes => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: ScreenPath,
    screenName: 'OrdersError',
    version: eventDetailVersion
  };
};
